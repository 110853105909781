<template>
  <b-col
    cols="6"
    xs="6"
    sm="6"
    md="4"
    lg="3"
    xl="3"
    xxl="2"
    class="d-flex align-item-center col-xxl-2 single-info-item"
  >
    <b-avatar
      v-if="icon != ''"
      :text="label.toString()"
      rounded
      class="mr-1 text-truncate"
      size="50px"
    >
      <slot color="text-truncate" name="img"> </slot>
      <feather-icon v-if="icon" :icon="icon" size="40" color="white" />
    </b-avatar>
    <b-avatar
      v-if="icon == ''"
      ref="previewEl"
      :src="src"
      :text="label.toString()"
      :variant="`light-black`"
      size="50px"
      rounded
      class="mr-1 gray-scale"
      style="padding: 5px"
    />
    <b-form-group
      :label="label"
      class="w-100 text-truncate shadow-sm single-item font-sm"
      :label-for="label"
    >
      <div :id="label">
        <slot />
      </div>
    </b-form-group>
  </b-col>
</template>

<script>
import { BAvatar, BCol, BFormGroup } from "bootstrap-vue";
export default {
  components: {
    BAvatar,
    BCol,
    BFormGroup,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    src: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.single-item label {
  font-size: 12px;
  font-weight: 500;
}
.single-item div {
  font-size: 12px;
  font-weight: 400;
}
.font-sm {
  font-size: 12px;
}
</style>

<template>
  <div>
    <div
      v-if="nutrientLoading"
      class="d-flex justify-content-center align-items-center"
    >
      <strong>Loading...</strong>
      <b-spinner class="ml-auto" />
    </div>
    <div v-if="!nutrientLoading" class="d-flex flex-column">
      <div
        class="d-flex flex-column"
        style="margin-left: -20px; margin-right: -20px"
      >
        <div
          class="d-flex justify-content-end"
          style="margin-left: 22px; margin-right: 22px"
        >
          <b-button
            v-b-modal.modalNutrients
            class="mb-2 align-self-end"
            variant="outline-primary"
            @click="getRationNutrients"
          >
            {{ $t("nutrientAdd") }}
          </b-button>
        </div>
        <b-table-lite
          v-if="ration.besinmaddeleri.length"
          ref="refNutrientListTable"
          :items="ration.besinmaddeleri"
          striped
          :small="true"
          :fields="fields"
          responsive
          class="editable-table"
          primary-key="nkey"
          show-empty
          bordered
          :empty-text="$t('noRecords')"
        >
          <template #cell(arrow)="{ item }">
            <feather-icon
              v-if="
                item.onerilenkarisim != 0 && item.onerilenkarisim < item.karisim
              "
              icon="ArrowUpIcon"
              class="text-success"
            />
            <feather-icon
              v-if="
                item.onerilenkarisim != 0 && item.onerilenkarisim > item.karisim
              "
              icon="ArrowDownIcon"
              class="text-danger"
            />
          </template>
          <template #cell(deger)="data">
            <span
              :id="data.item.nkey + '-deger'"
              :class="
                data.item.itemtextcolor == 'Green'
                  ? 'text-success'
                  : 'text-danger'
              "
            >
              {{
                parseFloat(data.item.deger)
                  .toFixed(nutrientDecimal)
                  .replace(".", ",")
              }}</span
            >
          </template>

          <template #cell(min)="data">
            <div
              class="editable text-right align-items-center position-relative"
            >
              <feather-icon
                v-if="data.item.min > data.item.max && data.item.max != 0"
                class="position-absolute"
                :class="
                  data.item.min > data.item.max && data.item.max != 0
                    ? 'text-danger'
                    : 'text-success'
                "
                icon="ArrowDownIcon"
                style="right: 0; top: 1px"
              />
              <b-form-input
                :id="data.item.ingredientname + 'min'"
                :class="
                  data.item.min > data.item.max && data.item.max != 0
                    ? 'text-danger'
                    : 'text-success'
                "
                class="text-right"
                size="sm"
                style="font-size: 1rem"
                :data-id="'nutrient-' + data.field.key + data.index"
                :value="
                  parseFloat(data.item.min)
                    .toFixed(nutrientDecimal)
                    .replace(',', '.')
                "
                type="number"
                :options="{
                  numeral: true,
                }"
                @change="(v) => (data.item.min = parseFloat(v))"
              />
            </div>
          </template>
          <template #cell(max)="data">
            <div
              class="editable align-items-center text-right position-relative"
            >
              <feather-icon
                v-if="data.item.min > data.item.max && data.item.max != 0"
                class="position-absolute"
                style="right: 0; top: 1px"
                :class="
                  data.item.min > data.item.max && data.item.max != 0
                    ? 'text-danger'
                    : 'text-success'
                "
                icon="ArrowUpIcon"
              />
              <b-form-input
                :id="data.item.nutrientname + 'max'"
                size="sm"
                class="text-right"
                style="font-size: 1rem"
                :data-id="'nutrient-' + data.field.key + data.index"
                :value="
                  parseFloat(data.item.max)
                    .toFixed(nutrientDecimal)
                    .replace(',', '.')
                "
                type="number"
                :options="{
                  numeral: true,
                }"
                @change="(v) => (data.item.max = parseFloat(v))"
              />
            </div>
          </template>
          <template #cell(kmde)="data">
            <span
              :id="data.item.id + '-kmde'"
              class="d-flex justify-content-end"
            >
              {{ calculatedKMde(data.item) }}</span
            >
          </template>
          <template #cell(delete)="data">
            <b-link @click="removeItem(data.index)">
              <div class="d-flex">
                <feather-icon class="text-danger" icon="Trash2Icon" />
                <small
                  class="d-none d-lg-inline align-middle ml-50 text-dark"
                  >{{ $t("delete") }}</small
                >
              </div>
            </b-link>
          </template>
        </b-table-lite>

        <b-modal
          id="modalNutrients"
          scrollable
          :title="$t('nutrients')"
          :cancel-title="$t('close')"
          :ok-title="$t('ok')"
          cancel-variant="outline-secondary"
          @ok="submitNutrient"
        >
          <b-form-group :label="$t('search')">
            <b-form-input v-model="search" @keyup="changeItem()" />
          </b-form-group>
          <b-table-lite
            :items="allNutrientsSearch"
            :fields="[
              {
                key: 'selected',
                label: '',
              },
              {
                key: 'nutrientname',
                label: $t('nutrientName'),
              },
            ]"
          >
            <template #cell(selected)="{ item }">
              <div class="d-flex">
                <b-form-checkbox
                  :key="item.id + '-' + item.selected"
                  :checked="item.selected == 1"
                  @change.native="changeSelected(item)"
                />
              </div>
            </template>
          </b-table-lite>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BSpinner,
  BTableLite,
  BLink,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BButton,

    BModal,

    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BSpinner,
    BTableLite,
    BLink,
  },

  data: function () {
    return {
      getUserData: getUserData,
      search: "",
      allNutrientsSearch: [],
      fields: [
        {
          key: "kod",
          label: this.$t("code"),
          class: "kod-col",
        },
        {
          key: "nutrientname",
          label: this.$t("nutrientName"),
          class: "name-col",
        },
        {
          key: "deger",
          label: this.$t("value"),
          class: "text-right",
          tdClass: "max-input-td",
        },
        {
          key: "birim",
          label: this.$t("unit"),
          class: "unit-col",
          tdClass: "max-input-td",
        },
        {
          key: "kmde",
          label: this.$t("kmde"),
          tdClass: "max-input-td",
        },

        {
          key: "min",
          label: this.$t("Min"),
          editable: true,
          type: "number",
          tdClass: "max-input-td",
        },
        {
          key: "max",
          label: this.$t("Max"),
          editable: true,
          type: "number",
          tdClass: "max-input-td",
        },

        { key: "delete", label: this.$t("delete"), tdClass: "remove-td" },
      ],
    };
  },
  computed: {
    nutrientDecimal() {
      return getUserData().nutrientdecimal;
    },
    priceDecimal() {
      return getUserData().pricedecimal;
    },
    allNutrients() {
      return this.$store.state.rationsModule.allNutrients;
    },
    ration() {
      return this.$store.state.rationsModule.selectedRation;
    },
    nutrientLoading() {
      return this.$store.state.rationsModule.nutrientLoading;
    },
    kuruMaddeVal() {
      return this.$store.state.rationsModule.kuruMaddeValue;
    },
  },
  async mounted() {
    await this.getRationNutrients();
  },
  methods: {
    changeSelected(item) {
      item.selected == 1 ? (item.selected = 0) : (item.selected = 1);
    },
    changeItem() {
      this.allNutrientsSearch = this.allNutrients.filter((x) => {
        return x.nutrientname
          .toLowerCase()
          .startsWith(this.search.toLowerCase());
      });
    },
    calculatedKMde({ deger, birim }) {
      if (deger != 0)
        if (birim == "MCal")
          return `${((deger / this.kuruMaddeVal) * 1000).toFixed(
            getUserData().nutrientdecimal
          )} KCal/Kg`;
        else if (birim == "Gram")
          return `${(deger / this.kuruMaddeVal / 10).toFixed(
            getUserData().nutrientdecimal
          )} %`;
        else if (birim == "Kg")
          return `${((deger / this.kuruMaddeVal) * 100).toFixed(
            getUserData().nutrientdecimal
          )} %`;
      return "0";
    },
    async getRationNutrients() {
      await this.$store.dispatch("rationsModule/getRationNutrients");
      this.allNutrientsSearch = this.allNutrients;
    },
    async submitNutrient() {
      await this.$store.dispatch("rationsModule/updateNutrients");
      this.search = "";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.closeButton {
  padding: 0px;
  position: absolute;
  right: 16px;
  top: -5px;
  cursor: pointer;

  :hover {
    color: #b50404;
    transition: all 301ms ease;
  }
}
</style>
<style>
table.editable-table {
  margin: auto;
}

.editable-table .data-cell {
  vertical-align: middle;
}

.editable-table .custom-checkbox {
  width: 50px;
}

.name-col {
  width: 220px;
}

.kod-col {
  width: 50px;
}

.min-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.min-col:hover {
  cursor: pointer;
}
.max-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.max-col .max-col:hover {
  cursor: pointer;
}
.age-col {
  width: 100px;
}

.date-col {
  width: 200px;
}

.is-active-col {
  width: 100px;
}
.editable-table table {
  width: 100%;
  border-collapse: collapse;
}
.editable-table table.b-table-lite {
  width: inherit !important;
  min-width: 1080px;
}
.remove-td {
  min-width: 50px;
  max-width: 75px;
  width: 50px;
}
.max-input-td {
  min-width: 100px;
  max-width: 150px;
  width: 100px;
}
</style>

<template>
  <div>
    <div v-if="rationLoading" class="d-flex align-items-center">
      <strong>Loading...</strong>
      <b-spinner class="ml-auto" />
    </div>
    <div v-if="!rationLoading">
      <b-card>
        <!-- Media -->
        <div class="d-flex justify-content-between flex-column flex-lg-row">
          <div
            class="d-flex flex-column flex-wrap justify-content-between border p-1 rounded mb-1"
          >
            <b-media class="mb-2 mr-md-2 w-50">
              <template #aside>
                <b-avatar
                  ref="previewEl"
                  class="text-truncate"
                  :text="rationData.fullname"
                  size="50px"
                  rounded
                >
                  <feather-icon icon="CalendarIcon" size="25" />
                </b-avatar>
              </template>
              <h6 class="mb-1">
                {{ rationData.rasyonadi }} {{ $t("rationWSuffix") }}
              </h6>
              <h6 class="mb-1">
                {{ dayjs(rationData.tarih).format("DD.MM.YYYY") }}
              </h6>
            </b-media>

            <b-media class="mb-2 mr-md-2 w-50">
              <template #aside>
                <b-avatar
                  ref="previewEl"
                  class="text-truncate"
                  :text="rationData.fullname"
                  size="50px"
                  rounded
                >
                  <span style="font-size: 24px">
                    {{ getUserData().currency }}
                  </span>
                </b-avatar>
              </template>
              <h6 class="mb-2">
                {{
                  rationData.rasyonfiyati == null
                    ? "0"
                    : parseFloat(rationData.rasyonfiyati).toFixed(
                        getUserData().pricedecimal
                      )
                }}{{ getUserData().currency }}
              </h6>
            </b-media>
          </div>

          <div>
            <h3>
              <b-button
                v-if="cozdu == null"
                variant="warning"
                class="d-flex align-items-center justify-content-center w-100"
              >
                <div class="d-flex mr-1">
                  <feather-icon
                    class="text-black-50"
                    size="25"
                    icon="MinusCircleIcon"
                  />
                </div>
                <span class="text-black-50"> {{ $t("lastSolution") }}</span>
              </b-button>
              <b-button
                v-if="cozdu != null && !cozdu"
                variant="danger"
                class="d-flex align-items-center justify-content-center w-100"
              >
                <div class="d-flex mr-1">
                  <feather-icon size="25" icon="XCircleIcon" />
                </div>
                {{ $t("notSolved") }}
              </b-button>
              <b-button
                v-if="cozdu"
                variant="success"
                class="d-flex align-items-center justify-content-center w-100"
              >
                <div class="d-flex mr-1">
                  <feather-icon size="25" icon="CheckCircleIcon" />
                </div>
                {{ $t("solved") }}
              </b-button>
            </h3>
          </div>
          <div class="d-flex flex-wrap mb-2 justify-content-between">
            <div class="d-flex flex-wrap flex-grow-1 justify-content-around">
              <b-button
                variant="success"
                style="height: 70px; min-width: 100px"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 d-flex flex-column align-items-center justify-content-around"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="solveRation()"
              >
                <component
                  :is="require('@/assets/images/icons/optimizeW.svg')"
                  color="text-truncate"
                  height="25"
                />
                <span>
                  {{ $t("solve") }}
                </span>
              </b-button>
              <b-button
                variant="primary"
                style="height: 70px; min-width: 100px"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 d-flex flex-column align-items-center justify-content-around"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="saveRation()"
              >
                <feather-icon icon="SaveIcon" size="25" />
                <span>
                  {{ $t("save") }}
                </span>
              </b-button>
            </div>
            <div class="d-flex flex-wrap flex-grow-1 justify-content-around">
              <b-button
                variant="primary"
                style="height: 70px; min-width: 100px"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 d-flex flex-column align-items-center justify-content-around"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="saveForProduction()"
              >
                <feather-icon icon="SaveIcon" size="25" />
                <span>
                  {{ $t("production") }}
                </span>
              </b-button>
              <b-button
                variant="danger"
                style="height: 70px; min-width: 100px"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 d-flex flex-column align-items-center justify-content-around"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="deleteRation()"
              >
                <feather-icon icon="TrashIcon" size="25" />
                <span>
                  {{ $t("delete") }}
                </span>
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Info: Input Fields -->
        <b-row class="space">
          <ration-info-single-item :label="$t('breed')">
            <template v-slot:img>
              <BeefSvg color="text-truncate" height="30" />
            </template>
            {{ animalType.find((x) => x.value == rationData.irkid).label }}
          </ration-info-single-item>
          <ration-info-single-item
            icon="FileTextIcon"
            :label="$t('rationModel')"
          >
            NASEM
          </ration-info-single-item>
          <ration-info-single-item v-if="rationData.ca" :label="$t('ca')">
            <template v-slot:img>
              <BeefSvg color="text-truncate" height="30" />
            </template>
            {{ rationData.ca }}
          </ration-info-single-item>
          <ration-info-single-item v-if="rationData.gcaa" :label="$t('gcaa')">
            <template v-slot:img>
              <KilosSvg color="text-truncate" height="35" />
            </template>
            {{ rationData.gcaa }}
          </ration-info-single-item>
          <ration-info-single-item v-if="rationData.bda" :label="$t('bda')">
            <template v-slot:img>
              <KilosSvg color="text-truncate" height="35" />
            </template>
            {{ rationData.bda }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.kgs"
            :label="$t('kgs')"
            icon="CalendarIcon"
          >
            {{ rationData.kgs }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.kgg"
            :label="$t('kgg')"
            icon="CalendarIcon"
          >
            {{ rationData.kgg }}
          </ration-info-single-item>

          <ration-info-single-item v-if="rationData.sv" :label="$t('sv')">
            <template v-slot:img>
              <MilkSvg color="text-truncate" height="40" />
            </template>
            {{ rationData.sv }}
          </ration-info-single-item>

          <ration-info-single-item
            v-if="rationData.sy"
            :label="$t('sy')"
            icon="PercentIcon"
          >
            {{ rationData.sy }}
          </ration-info-single-item>

          <ration-info-single-item
            v-if="rationData.ls"
            :label="$t('ls')"
            icon="BarChartIcon"
          >
            {{ rationData.ls }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.sp"
            :label="$t('sp')"
            icon="PercentIcon"
          >
            {{ rationData.sp }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.dsv"
            :label="$t('dsv') + ' ' + $t('DSV')"
            icon="?"
          >
            {{ rationData.dsv }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.kabayemorani"
            :label="$t('minKaba')"
            icon="PercentIcon"
          >
            {{ rationData.kabayemorani }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.kabayemoranimax"
            :label="$t('maxKaba')"
            icon="PercentIcon"
          >
            {{ rationData.kabayemoranimax }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.hayvantipiid"
            :label="$t('animalType')"
            icon="PieChartIcon"
          >
            {{
              hayvanTipleri.find((x) => x.value == rationData.hayvantipiid)
                .label
            }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.mggs"
            :label="$t('mggs')"
            icon="RefreshCwIcon"
          >
            {{ rationData.mggs }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.mye"
            :label="$t('mye')"
            icon="GitMergeIcon"
          >
            {{ rationData.mye }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.shy"
            :label="$t('shy')"
            icon="GitCommitIcon"
          >
            {{ rationData.shy }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.bcs"
            :label="$t('bcs')"
            icon="TrendingUpIcon"
          >
            {{ rationData.bcs }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.sicaklik"
            :label="$t('temperature') + ' ' + $t('C')"
            icon="ThermometerIcon"
          >
            {{ rationData.sicaklik }}
          </ration-info-single-item>

          <!-- Bunlar belli değil -->
          <ration-info-single-item
            v-if="rationData.eca && false"
            :label="$t('eca')"
            icon="CalendarIcon"
          >
            {{ rationData.eca }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.zemin && false"
            :label="$t('zemin')"
            icon="CalendarIcon"
          >
            {{ rationData.zemin }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.by && false"
            :label="$t('by')"
            icon="PercentIcon"
          >
            {{ rationData.by }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.gh && false"
            :label="$t('gh')"
            icon="PercentIcon"
          >
            {{ rationData.gh }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.lg && false"
            :label="$t('lg')"
            icon="PercentIcon"
          >
            {{ rationData.lg }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.katyonanyondegeri && false"
            :label="$t('katyonanyondegeri')"
            icon="PercentIcon"
          >
            {{ rationData.katyonanyondegeri }}
          </ration-info-single-item>
          <ration-info-single-item
            v-if="rationData.ilkdogum && false"
            :label="$t('ilkdogum')"
            icon="PercentIcon"
          >
            {{ rationData.ilkdogum }}
          </ration-info-single-item>
        </b-row>
      </b-card>
      <b-card class="mt-1">
        <slot />
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BCard,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import RationInfoSingleItem from "./RationInfoSingleItem.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import BeefSvg from "@/assets/images/icons/beef.svg";
import MilkSvg from "@/assets/images/icons/milk.svg";
import KilosSvg from "@/assets/images/icons/kilos.svg";
import MoneySvg from "@/assets/images/icons/money.svg";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
export default {
  components: {
    RationInfoSingleItem,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BBadge,
    FeatherIcon,
    BSpinner,
    //Svg
    BeefSvg,
    MilkSvg,
    KilosSvg,
    MoneySvg,
  },
  props: {
    rationData: {
      type: Object,
      required: true,
    },
    rationView: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      getUserData: getUserData,
      dayjs,
      hayvanTipleri: [
        { label: this.$t("heifer"), value: 3 },
        { label: this.$t("firstPregnancy"), value: 4 },
        { label: this.$t("inLactation"), value: 5 },
        { label: this.$t("inDry"), value: 6 },
      ],
      animalType: [],
    };
  },
  computed: {
    rationLoading() {
      return this.$store.state.rationsModule.rationLoading;
    },
    cozdu() {
      return this.$store.state.rationsModule.cozdu;
    },
  },
  async mounted() {
    var animalTypes = await this.$http.get("/Animals/GetAnimalBreeds");
    this.animalType = animalTypes.data.map((x) => {
      return { label: x.name, value: x.id };
    });
  },
  methods: {
    solveRation() {
      this.$store.dispatch("rationsModule/solveRation");
    },
    saveRation() {
      this.$store.dispatch("rationsModule/saveRation");
    },
    saveForProduction() {
      this.$store.dispatch("rationsModule/saveRationForProduction");
    },
    async deleteRation() {
      await this.$store.dispatch("rationsModule/deleteRation", {
        rationid: this.rationData.id,
        plantid: this.rationData.plantid,
        tur: this.$route.params.tur,
      });
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#price label {
  font-size: 18px;
  font-weight: 500;
}
#price div {
  font-size: 18px;
  font-weight: 500;
}
</style>

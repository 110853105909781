<template>
  <div>
    <component :is="'b-card'" v-if="rationData.hammaddeler == null">
      <h4 class="alert-heading">
        {{ $t("xFetchError", { value: $t("ration") }) }}
      </h4>
      <div class="alert-body">
        {{ $t("xNotFound", { value: $t("ration") }) }}
        <b-link class="alert-link" :to="{ name: 'plants' }" />
      </div>
    </component>
    <div v-if="rationData.hammaddeler != null">
      <ration-view-ration-info
        :key="rationData.id"
        :ration-data="rationData.rasyon[0]"
        class="mt-2 pt-75"
        :ration-view="rationView"
      >
        <b-tabs v-if="rationData.rasyon.length" active pills>
          <!-- Tab: Hammaddeler -->
          <b-tab>
            <template #title>
              <IngredientSvg height="16" />
              <span class="d-none d-sm-inline">{{ $t("ingredients") }}</span>
            </template>
            <ration-view-ration-ingredient />
          </b-tab>
          <!-- Tab: Besin Maddeleri -->
          <b-tab>
            <template #title>
              <NutrientSvg height="16" />
              <span class="d-none d-sm-inline">{{ $t("nutrients") }}</span>
            </template>
            <ration-view-ration-nutrient />
          </b-tab>
        </b-tabs>
      </ration-view-ration-info>
    </div>
  </div>
</template>

<script>
import { BTab, BTabs, BCard, BLink } from "bootstrap-vue";
import router from "@/router";
import store from "@/store";
import RationViewRationInfo from "./RationViewRationInfo.vue";
import RationViewRationNutrient from "./RationViewRationNutrient.vue";
import RationViewRationIngredient from "./RationViewRationIngredient.vue";
import IngredientSvg from "@/assets/images/icons/ingredients.svg";
import NutrientSvg from "@/assets/images/icons/products.svg";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BLink,
    RationViewRationInfo,
    RationViewRationNutrient,
    RationViewRationIngredient,
    IngredientSvg,
    NutrientSvg,
  },

  data: function () {
    return {
      rationView: {
        count: 0,
        caGrid: false,
        gaGrid: false,
        svGrid: false,
        syGrid: false,
        kacgunoncedogurduGrid: false,
        laktasyonGrid: false,
        minkabalar: false,
        dayspregnantGrid: false,
        buzagiagirligiGrid: false,
        kacgundursagiliyorGrid: false,
        buzagiyasiGrid: false,
        gebeliksuresiGrid: false,
        conditionscoreGrid: false,
        toplamgrupsayisiGrid: false,
        dogumakalangunGrid: false,
        rasyongunGrid: false,
        karkasrandimaniGrid: false,
      },
    };
  },
  computed: {
    rationData() {
      return store.state.rationsModule.selectedRation;
    },
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "rationView") {
      to.meta.breadcrumb = [];
      to.meta.breadcrumb.push({
        to: "/plants",
        text: `plants`,
        active: true,
      });
      to.meta.breadcrumb.push({
        text: `plant`,
        to: `/plant/${to.params.id}`,
        active: true,
      });
      to.meta.breadcrumb.push({
        text: `${to.params.tur}Rations`,
        to: `/rations/${to.params.tur}`,
        active: false,
      });
      to.meta.breadcrumb.push({
        text: "rationInformation",
        active: true,
      });
    }
    next();
  },

  async mounted() {
    await store.dispatch("rationsModule/fetchRation", {
      id: router.currentRoute.params.rationid,
      tur: this.upperFirstLetter(router.currentRoute.params.tur),
      plantid: router.currentRoute.params.id,
    });

    await store.dispatch("rationsModule/getRationIngredients");
    this.rationTypeView();
  },
  methods: {
    upperFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async rationTypeView() {
      this.$set(this.rationView, "caGrid", true);
      this.$set(this.rationView, "svGrid", true);
      this.$set(this.rationView, "syGrid", true);
      this.$set(this.rationView, "kacgunoncedogurduGrid", true);
      this.$set(this.rationView, "laktasyonGrid", true);
      this.$set(this.rationView, "minkabalar", true);
      this.$set(this.rationView, "gaGrid", true);
      this.$set(this.rationView, "kacgundursagiliyorGrid", true);
      this.$set(this.rationView, "dayspregnantGrid", true);
      this.$set(this.rationView, "buzagiagirligiGrid", true);
      this.$set(this.rationView, "laktasyonGrid", true);
      this.$set(this.rationView, "buzagiyasiGrid", true);
      this.$set(this.rationView, "gebeliksuresiGrid", true);
      this.$set(this.rationView, "conditionscoreGrid", true);
      this.$set(this.rationView, "toplamgrupsayisiGrid", true);
      this.$set(this.rationView, "dogumakalangunGrid", true);
      this.$set(this.rationView, "rasyongunGrid", true);
      this.$set(this.rationView, "rasyongunGrid", true);
      this.$set(this.rationView, "karkasrandimaniGrid", true);
    },
  },
};
</script>

<style></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.nutrientLoading)?_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('strong',[_vm._v("Loading...")]),_c('b-spinner',{staticClass:"ml-auto"})],1):_vm._e(),(!_vm.nutrientLoading)?_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"margin-left":"-20px","margin-right":"-20px"}},[_c('div',{staticClass:"d-flex justify-content-end",staticStyle:{"margin-left":"22px","margin-right":"22px"}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalNutrients",modifiers:{"modalNutrients":true}}],staticClass:"mb-2 align-self-end",attrs:{"variant":"outline-primary"},on:{"click":_vm.getRationNutrients}},[_vm._v(" "+_vm._s(_vm.$t("nutrientAdd"))+" ")])],1),(_vm.ration.besinmaddeleri.length)?_c('b-table-lite',{ref:"refNutrientListTable",staticClass:"editable-table",attrs:{"items":_vm.ration.besinmaddeleri,"striped":"","small":true,"fields":_vm.fields,"responsive":"","primary-key":"nkey","show-empty":"","bordered":"","empty-text":_vm.$t('noRecords')},scopedSlots:_vm._u([{key:"cell(arrow)",fn:function(ref){
var item = ref.item;
return [(
              item.onerilenkarisim != 0 && item.onerilenkarisim < item.karisim
            )?_c('feather-icon',{staticClass:"text-success",attrs:{"icon":"ArrowUpIcon"}}):_vm._e(),(
              item.onerilenkarisim != 0 && item.onerilenkarisim > item.karisim
            )?_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"ArrowDownIcon"}}):_vm._e()]}},{key:"cell(deger)",fn:function(data){return [_c('span',{class:data.item.itemtextcolor == 'Green'
                ? 'text-success'
                : 'text-danger',attrs:{"id":data.item.nkey + '-deger'}},[_vm._v(" "+_vm._s(parseFloat(data.item.deger) .toFixed(_vm.nutrientDecimal) .replace(".", ",")))])]}},{key:"cell(min)",fn:function(data){return [_c('div',{staticClass:"editable text-right align-items-center position-relative"},[(data.item.min > data.item.max && data.item.max != 0)?_c('feather-icon',{staticClass:"position-absolute",class:data.item.min > data.item.max && data.item.max != 0
                  ? 'text-danger'
                  : 'text-success',staticStyle:{"right":"0","top":"1px"},attrs:{"icon":"ArrowDownIcon"}}):_vm._e(),_c('b-form-input',{staticClass:"text-right",class:data.item.min > data.item.max && data.item.max != 0
                  ? 'text-danger'
                  : 'text-success',staticStyle:{"font-size":"1rem"},attrs:{"id":data.item.ingredientname + 'min',"size":"sm","data-id":'nutrient-' + data.field.key + data.index,"value":parseFloat(data.item.min)
                  .toFixed(_vm.nutrientDecimal)
                  .replace(',', '.'),"type":"number","options":{
                numeral: true,
              }},on:{"change":function (v) { return (data.item.min = parseFloat(v)); }}})],1)]}},{key:"cell(max)",fn:function(data){return [_c('div',{staticClass:"editable align-items-center text-right position-relative"},[(data.item.min > data.item.max && data.item.max != 0)?_c('feather-icon',{staticClass:"position-absolute",class:data.item.min > data.item.max && data.item.max != 0
                  ? 'text-danger'
                  : 'text-success',staticStyle:{"right":"0","top":"1px"},attrs:{"icon":"ArrowUpIcon"}}):_vm._e(),_c('b-form-input',{staticClass:"text-right",staticStyle:{"font-size":"1rem"},attrs:{"id":data.item.nutrientname + 'max',"size":"sm","data-id":'nutrient-' + data.field.key + data.index,"value":parseFloat(data.item.max)
                  .toFixed(_vm.nutrientDecimal)
                  .replace(',', '.'),"type":"number","options":{
                numeral: true,
              }},on:{"change":function (v) { return (data.item.max = parseFloat(v)); }}})],1)]}},{key:"cell(kmde)",fn:function(data){return [_c('span',{staticClass:"d-flex justify-content-end",attrs:{"id":data.item.id + '-kmde'}},[_vm._v(" "+_vm._s(_vm.calculatedKMde(data.item)))])]}},{key:"cell(delete)",fn:function(data){return [_c('b-link',{on:{"click":function($event){return _vm.removeItem(data.index)}}},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"Trash2Icon"}}),_c('small',{staticClass:"d-none d-lg-inline align-middle ml-50 text-dark"},[_vm._v(_vm._s(_vm.$t("delete")))])],1)])]}}],null,false,3605132714)}):_vm._e(),_c('b-modal',{attrs:{"id":"modalNutrients","scrollable":"","title":_vm.$t('nutrients'),"cancel-title":_vm.$t('close'),"ok-title":_vm.$t('ok'),"cancel-variant":"outline-secondary"},on:{"ok":_vm.submitNutrient}},[_c('b-form-group',{attrs:{"label":_vm.$t('search')}},[_c('b-form-input',{on:{"keyup":function($event){return _vm.changeItem()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('b-table-lite',{attrs:{"items":_vm.allNutrientsSearch,"fields":[
            {
              key: 'selected',
              label: '',
            },
            {
              key: 'nutrientname',
              label: _vm.$t('nutrientName'),
            } ]},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{key:item.id + '-' + item.selected,attrs:{"checked":item.selected == 1},nativeOn:{"change":function($event){return _vm.changeSelected(item)}}})],1)]}}],null,false,1552776455)})],1)],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
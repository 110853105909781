import { render, staticRenderFns } from "./RationViewRationNutrient.vue?vue&type=template&id=18ca07c7"
import script from "./RationViewRationNutrient.vue?vue&type=script&lang=js"
export * from "./RationViewRationNutrient.vue?vue&type=script&lang=js"
import style0 from "./RationViewRationNutrient.vue?vue&type=style&index=0&id=18ca07c7&prod&lang=scss"
import style1 from "./RationViewRationNutrient.vue?vue&type=style&index=1&id=18ca07c7&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
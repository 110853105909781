var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.ingredientLoading)?_c('div',{staticClass:"d-flex align-items-center"},[_c('strong',[_vm._v("Loading...")]),_c('b-spinner',{staticClass:"ml-auto"})],1):_vm._e(),(!_vm.ingredientLoading)?_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div'),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalingredients",modifiers:{"modalingredients":true}}],staticClass:"mb-2 align-self-end",attrs:{"variant":"outline-primary"},on:{"click":_vm.getRationIngredients}},[_vm._v(" "+_vm._s(_vm.$t("ingredientAdd"))+" ")])],1),(_vm.ration.hammaddeler.length)?_c('div',{staticStyle:{"margin-left":"-21px","margin-right":"-21px"}},[(true)?_c('b-table-lite',{ref:"refIngredienttListTable",staticClass:"editable-table",attrs:{"items":_vm.ration.hammaddeler,"striped":"","small":true,"fields":_vm.fields,"responsive":"","primary-key":"ikey","bordered":"","show-empty":"","empty-text":_vm.$t('noRecords')},scopedSlots:_vm._u([{key:"cell(karisim)",fn:function(data){return [_c('div',{staticClass:"text-right"},[_c('span',{class:data.item.itemtextcolor == 'Green' ||
                ((parseFloat(data.item.karisim) <=
                  parseFloat(data.item.max) ||
                  parseFloat(data.item.max) == 0) &&
                  parseFloat(data.item.karisim) >= parseFloat(data.item.min))
                  ? 'text-success'
                  : 'text-danger',attrs:{"id":data.item.ikey + '-karisim'}},[_vm._v(" "+_vm._s(parseFloat(data.item.karisim) .toFixed(_vm.ingredientDecimal) .replace(".", ",")))])])]}},{key:"cell(ingredientname)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.ingredientname)+" ")]}},{key:"cell(kod)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.kod)+" ")]}},{key:"cell(min)",fn:function(data){return [_c('div',{staticClass:"editable text-right align-items-center position-relative"},[(
                parseFloat(data.item.min) > parseFloat(data.item.max) &&
                data.item.max != 0
              )?_c('feather-icon',{staticClass:"position-absolute",class:parseFloat(data.item.min) > parseFloat(data.item.max) &&
                data.item.max != 0
                  ? 'text-danger'
                  : '',staticStyle:{"right":"0","top":"1px"},attrs:{"icon":"ArrowDownIcon"}}):_vm._e(),_c('b-form-input',{staticClass:"p-relative text-right",class:parseFloat(data.item.min) > parseFloat(data.item.max) &&
                data.item.max != 0
                  ? 'text-danger'
                  : '',staticStyle:{"font-size":"1rem"},attrs:{"id":data.item.ingredientname + 'min',"size":"sm","data-id":'nutrient-' + data.field.key + data.index,"value":parseFloat(data.item.min)
                  .toFixed(_vm.ingredientDecimal)
                  .replace(',', '.'),"type":"number","options":{
                numeral: true,
              }},on:{"change":function (v) { return (data.item.min = parseFloat(v)); }}})],1)]}},{key:"cell(max)",fn:function(data){return [_c('div',{staticClass:"editable text-right"},[_c('b-form-input',{staticClass:"text-right",staticStyle:{"font-size":"1rem"},attrs:{"id":data.item.ingredientname + 'max',"size":"sm","data-id":'ingredient-' + data.field.key + data.index,"value":parseFloat(data.item.max)
                  .toFixed(_vm.ingredientDecimal)
                  .replace(',', '.'),"type":"number","options":{
                numeral: true,
              }},on:{"change":function (v) { return (data.item.max = parseFloat(v)); }}})],1)]}},{key:"cell(price)",fn:function(data){return [_c('div',{staticClass:"editable text-right"},[_c('b-form-input',{staticClass:"text-right",staticStyle:{"font-size":"1rem"},attrs:{"id":data.item.ingredientname + 'Price',"size":"sm","data-id":'ingredient-' + data.field.key + data.index,"value":parseFloat(data.item.price)
                  .toFixed(_vm.priceDecimal)
                  .replace(',', '.'),"type":"number","options":{
                numeral: true,
              }},on:{"change":function (v) { return (data.item.price = parseFloat(v)); }}})],1)]}},{key:"cell(delete)",fn:function(data){return [_c('b-link',{on:{"click":function($event){return _vm.removeItem(data.index)}}},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"Trash2Icon"}}),_c('small',{staticClass:"d-none d-lg-inline align-middle ml-50 text-dark"},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])],1)])]}}],null,false,2793465513)}):_vm._e()],1):_vm._e(),_c('b-modal',{attrs:{"id":"modalingredients","scrollable":"","title":_vm.$t('ingredients'),"cancel-title":_vm.$t('close'),"ok-title":_vm.$t('ok'),"cancel-variant":"outline-secondary"},on:{"ok":_vm.submitIngredient}},[_c('b-form-group',{attrs:{"label":_vm.$t('search')}},[_c('b-form-input',{on:{"keyup":function($event){return _vm.changeItem()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('b-table-lite',{attrs:{"items":_vm.allIngredientsSearch,"fields":[
          {
            key: 'selected',
            label: '',
          },
          {
            key: 'ingredientname',
            label: _vm.$t('ingredientName'),
          } ]},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{key:item.id + '-' + item.selected,attrs:{"checked":item.selected == 1},nativeOn:{"change":function($event){return _vm.changeSelected(item)}}})],1)]}}],null,false,1552776455)})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }